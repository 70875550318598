import '../../assets/css/common.css'
import {CButton, CSpinner} from '@coreui/react'
import {Confirm} from 'react-st-modal'
import Button from '@mui/material/Button'
import {useNavigate} from 'react-router-dom'
import NumericInput from 'react-numeric-input'
import {CContainer, CRow, CCol} from '@coreui/react'
import CreatableSelect from "react-select/creatable";
import jwtInterceoptor from '../../views/shared/jwtInterceptor'
import React, {useState, useEffect, useMemo, FormEvent} from 'react'
import useCurrentCompanyUsersWithAccessToExtracto from "../../hooks/useCurrentCompanyUsersWithAccessToExtracto";
import {IoIosArrowBack} from "react-icons/io";

interface FormValues {
	input_weight: number,
	strain_name: string,
	pressure: number,
	temperature1: number,
	operator: string,
	current_date: string
}

interface FormErrors {
	run_number?: string;
	current_date?: string;
	input_weight?: string;
	strain_name?: string;
	pressure?: string;
	temperature1?: string;
	operator?: string;
}

interface Device {
	name: string;
}

interface User {
	id: number,
	first_name?: string,
	last_name?: string,
	email: string
}

function InputParameters() {
	const navigate = useNavigate()
	const currentCompanyUsersWithAccessToExtracto = useCurrentCompanyUsersWithAccessToExtracto();
	const initialValues: FormValues = {
		input_weight: 0,
		strain_name: '',
		pressure: 0,
		temperature1: 0,
		operator: localStorage.getItem('UserName') || '',
		current_date: new Date().toLocaleDateString(),
	}
	const [values, setValues] = useState<FormValues>(initialValues)
	const [errors, setErrors] = useState<FormErrors>({})
	const [loader, setLoader] = useState(false)
	
	let optionsForOperatorSelectField
	const isDemoEnvironment = window.location.href.includes('demo');
	
	if (currentCompanyUsersWithAccessToExtracto) {
		optionsForOperatorSelectField = currentCompanyUsersWithAccessToExtracto.map((user: User) => ({
			label: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email,
			value: user.id
		}));
	}
	
	//if demo url
	if (isDemoEnvironment) {
		optionsForOperatorSelectField = [
			{label: 'Operator 1', value: 1},
			{label: 'Operator 2', value: 2},
			{label: 'Operator 3', value: 3},
			{label: 'Operator 4', value: 4},
		];
	}
	
	const handleChange = (value: number | null | string, inputName: string) => {
		
		let error = '';
		let newValue = value as number;
		
		switch (inputName) {
			case 'pressure':
				if (newValue < 0 || newValue > 100) {
					error = 'Pressure must be between 0 and 100 psi.';
				}
				break;
			case 'input_weight':
				if (newValue < 0 || newValue > 99) {
					error = 'Input weight must be between 0 and 99 lbs.';
				}
				break;
			default:
				break;
		}
		
		if (!error) {
			setValues((prevValues) => ({
				...prevValues,
				[inputName]: newValue,
			}));
		}
		
		setErrors((prevErrors) => ({
			...prevErrors,
			[inputName]: error,
		}));
	};
	
	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		
		// Validate form field values, if not validated, please return.
		const validationErrors = validateForm(values)
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors)
			return
		}
		
		try {
			setLoader(true)
			let response;
			
			if (isDemoEnvironment) {
				// Mock response for demo environment
				response = {
					data: {
						id: '1', // Example ID for the mock response
						...values, // Spread in the values to simulate a full response
					},
				};
			} else {
				// Actual API request
				response = await jwtInterceoptor.post(
					`${process.env.REACT_APP_API_URL}/user/api/input-parameters/`,
					values,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					}
				);
			}
			setLoader(false)
			localStorage.setItem('input_parameter', response?.data?.id);
			localStorage.setItem('run', 'true')
			navigate('/ExtractoPredictionDashboard', {
				state: {
					data: {
						strain: values.strain_name,
						input_weight: values.input_weight,
						operator: values.operator,
						pressure: values.pressure,
						temperature: values.temperature1,
						run: true
					}
				}
			})
			
		} catch (err) {
			
			// suppress errors on demo
			// only runs if the env is not demo
			if(!isDemoEnvironment){
				await Confirm(
					'Error occurred during processing request. Please contact administrator.',
					'Error',
				)
			}
			setLoader(false)
			localStorage.setItem('run', 'true')
			navigate('/ExtractoPredictionDashboard', {
				state: {
					data: {
						input_weight: values.input_weight,
						operator: values.operator,
						strain: values.strain_name,
						pressure: values.pressure,
						temperature: values.temperature1,
						run: true
					}
				}
			})
		}
	}
	
	// Perform form validation for errors
	const validateForm = (formValues: FormValues) => {
		const errors: FormErrors = {}
		
		if (formValues.strain_name.trim() === '') {
			errors.strain_name = 'Strain Name is required'
		}
		if (formValues.pressure <= 0) {
			errors.pressure = 'Pressure must be greater than 0'
		}
		
		// temperature must be between -20 and -100
		const temperature = parseFloat(String(formValues.temperature1));

		// Check if the value is a valid number
		if (isNaN(temperature)) {
			errors.temperature1 = 'Value must be a valid number between -100 and -20';
		} else if (temperature < -100 || temperature > -20) {
			// Check if the number is within the valid range
			errors.temperature1 = 'Temperature must be between -100 and -20';
		}

		if (formValues.operator === null) {
			errors.operator = 'Operator is required'
		}
		
		return errors
	}
	
	const devices = useMemo(() => {
		const storedDevices = localStorage.getItem('user_devices');
		const parsedDevices = storedDevices ? JSON.parse(storedDevices) : [];
		
		const flowMeterExists = parsedDevices.some((device: Device) => device.name === 'Flow Meter');
		const spectrometerExists = true;
		
		return {
			isFlowMeter: flowMeterExists,
			isSpectrometer: spectrometerExists,
		};
	}, []);
	
	useEffect(() => {
		const fetchFlowMeterData = async () => {
			try {
				
				const formData = new FormData()
				formData.append('air_reference_scan_id', '')
				formData.append('solvent_scan_id', '')
				formData.append('sample_scan_id', '')
				
				const responseFlowMeter = await jwtInterceoptor.post(
					`${process.env.REACT_APP_LOCAL_API_URL}/ScanFlowMeter`,
					formData,
					{
						headers: {'Content-Type': 'multipart/form-data'},
					},
				)
				
				
				if (responseFlowMeter.status === 200) {
					const {
						electronicTemp,
						temperature,
					} = responseFlowMeter.data;
					setValues((prevValues) => ({
						...prevValues,
						temperature1: temperature,
						pressure: electronicTemp,
						
					}))
				}
			} catch (error) {
				console.error('Error fetching flow meter data:', error);
			}
		};
		
		// Check if the flow meter exists before fetching data
		if (devices?.isFlowMeter) {
			fetchFlowMeterData();
		}
		
	}, [devices?.isFlowMeter]);
	
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
				width: '100%',
			}}
		>
			{/* Left Column */}
			<div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
				<CButton
					color=""
					onClick={() => {
						navigate('/ExtractoPredictionDashboard');
					}}
					style={{
						fontFamily: 'Inter',
						fontWeight: '600',
						fontSize: '14px',
						color: '#8292fa',
						backgroundColor: 'transparent',
						border: 'none',
					}}
				>
					<IoIosArrowBack style={{marginRight: '5px'}}/>
					Back to Extracto Prediction Dashboard
				</CButton>
			</div>
			
			{/* Center Column */}
			<div style={{flex: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				{loader ? (
					<CSpinner color="success" className="spinnerStyle"/>
				) : (
					<>
						<br/>
						<br/>
						<h2 className="text-line input-text">Please provide following values</h2>
						<form onSubmit={handleSubmit}>
							<CContainer>
								<CRow>
									<CCol md="6">
										<div className="label-color">
											<label className="form-label" htmlFor="strain_name">
												Strain Name:
											</label>
											<br/>
											<input
												type="text"
												className="form-control"
												id="strain_name"
												value={values.strain_name}
												onChange={(event) => handleChange(event.target.value, 'strain_name')}
												onFocus={() =>
													setErrors((prevErrors) => ({
														...prevErrors,
														strain_name: '',
													}))
												}
											/>
											{errors.strain_name && <p className="error">{errors.strain_name}</p>}
										</div>
									</CCol>
									<CCol md="6">
										<div className="label-color">
											<label className="form-label" htmlFor="pressure">
												Pressure (psi):
											</label>
											<br/>
											<NumericInput
												className="form-control"
												style={{}}
												id="pressure"
												min={0}
												max={100}
												value={values.pressure}
												onChange={(value) => handleChange(value, 'pressure')}
												onFocus={() =>
													setErrors((prevErrors) => ({
														...prevErrors,
														pressure: '',
													}))
												}
												disabled={devices?.isFlowMeter}
											/>
											{errors.pressure && <p className="error">{errors.pressure}</p>}
										</div>
									</CCol>
								</CRow>
								<CRow>
									<CCol md="6">
										<div className="label-color">
											<label className="form-label" htmlFor="temperature1">
												Temperature (°C):
											</label>
											<br/>
											<input
												className="form-control"
												id="temperature1"
												type="text"
												value={values.temperature1}
												onChange={(e) => handleChange(e.target.value, 'temperature1')}
												onFocus={() =>
													setErrors((prevErrors) => ({
														...prevErrors,
														temperature1: '',
													}))
												}
											/>
											{errors.temperature1 && <p className="error">{errors.temperature1}</p>}
										</div>
									</CCol>
									<CCol md="6">
										<div className="label-color">
											<label className="form-label" htmlFor="input_weight">
												Input Weight (Lbs):
											</label>
											<br/>
											<NumericInput
												style={{}}
												className="form-control"
												id="input_weight"
												value={values.input_weight}
												min={0}
												max={99}
												onChange={(value) => handleChange(value, 'input_weight')}
												onFocus={() =>
													setErrors((prevErrors) => ({
														...prevErrors,
														input_weight: '',
													}))
												}
											/>
											{errors.input_weight && <p className="error">{errors.input_weight}</p>}
										</div>
									</CCol>
									<CCol md="6">
										<div className="label-color">
											<label className="form-label" htmlFor="operator">
												Operator:
											</label>
											<br/>
											<CreatableSelect
												data-testid="operator"
												id="operator"
												inputId={"operator"}
												className="classic"
												isSearchable={true}
												options={optionsForOperatorSelectField}
												onChange={(selectedOption) => handleChange(selectedOption ? selectedOption.value : null, 'operator')}
												onFocus={() =>
													setErrors((prevErrors) => ({
														...prevErrors,
														location: '',
													}))
												}
											/>
											{errors.operator && <p className="error">{errors.operator}</p>}
										</div>
									</CCol>
								</CRow>
							</CContainer>
							<br/>
							<div className="centered-container">
								<Button
									variant="contained"
									type="submit"
									style={{
										backgroundColor: '#19d22c',
										color: '#000000',
										fontSize: '14px',
										width: '250px',
										height: '45px',
										marginBottom: '15px',
										fontWeight: '600',
									}}
								>
									Initiate Run
								</Button>
							</div>
						</form>
					</>
				)}
			</div>
			
			{/* Right Column */}
			<div style={{flex: 1}}></div>
		</div>
	);
	
	
}

export default InputParameters
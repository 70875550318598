import React from 'react'
// import {CFooter} from '@coreui/react'

const AppFooter = () => {
	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'white',
      height: '30px',
      width: '100%',
      borderTop: '1px solid #e0e0e0',
		}}>
			<p style={{color: 'darkgrey', fontSize: '12px', marginTop: '10px'}}> 2024 Newton Insights @ All rights reserved</p>

		</div>
	)
}

export default AppFooter

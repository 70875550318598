import React from "react";
import {useDialog} from "react-st-modal";
import { useMediaQuery } from "react-responsive";

interface ScanResultsProps {
	question: string;
	yesOption: string;
	noOption: string | null;
	centerYesButton?: boolean;
}

const ScanResults: React.FC<ScanResultsProps> = ({ question, yesOption, noOption, centerYesButton = false }) => {

	const dialog = useDialog();
  const isTablet = useMediaQuery({ maxWidth: 992 });

	return (
		<div className="GridComponentDiv">
			<br/>
			<h3
				style={{
					fontSize: isTablet ? "16px" : "25px",
					fontWeight: "400",
					fontFamily: "Inter",
					color: "#000000",
					marginBottom: isTablet ? "15px" : "20px",
          padding: "0px 5px"
				}}
			>
				{question}
			</h3>

			<div
				style={{
					width: isTablet ? "80%" : "60%",
					display: "flex",
					justifyContent: "space-around",
					marginTop: isTablet ? "20px" : "40px",
				}}
			></div>
			<button
				className={"btn btn-success" + (centerYesButton ? " d-block m-auto" : "")}
				style={{
					fontSize: isTablet ? "14px" : "16px",
					padding: isTablet ? "8px 16px" : "10px 20px",
					minWidth: isTablet ? "120px" : "150px"
				}}
				onClick={() => {
					dialog.close(1);
				}}
			>
				{yesOption}
			</button>
			{
				noOption &&
				<button
					className="btn btn-primary"
					style={{
						fontSize: isTablet ? "14px" : "16px",
						padding: isTablet ? "8px 16px" : "10px 20px",
						minWidth: isTablet ? "120px" : "150px",
						marginLeft: isTablet ? "8px" : "10px"
					}}
					onClick={() => {
						dialog.close(0);
					}}
				>
					{noOption}
				</button>
			}
			<br/>
		</div>
	);
};

export default ScanResults;

import React, { useState, useEffect } from "react";
import { TiPlug } from "react-icons/ti";
import GetTestStatus from "../GetTestStatus";
import { CiCircleCheck } from "react-icons/ci";
import CreatableSelect from "react-select/creatable";
import { BsExclamationCircle } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CButton,
} from "@coreui/react";

// Define the types for the props
interface Connection {
  id: string;
  machine_name: string;
  dated?: string;
}

type SetSelectedConnection = (connection: Connection | undefined) => void;

interface SelectYourHardwareProps {
  selectedConnection?: Connection;
  setSelectedConnection: SetSelectedConnection;
  connections: Connection[];
  deviceStatus: boolean;
  handleConnections: () => void;
}

const SelectYourHardware: React.FC<SelectYourHardwareProps> = ({
  selectedConnection,
  setSelectedConnection,
  connections,
  deviceStatus,
  handleConnections,
}) => {
  const isTablet = useMediaQuery({ maxWidth: 714, maxHeight: 1200 });
	const isTabletLandscape = useMediaQuery({ maxWidth: 1200, maxHeight: 714, orientation: 'landscape' });
  const [buttonColor, setButtonColor] = useState("#D8D8D8");
  const [textColor, setTextColor] = useState("#000000");

  useEffect(() => {
    // Move helper functions inside useEffect
    const getButtonColor = () => {
      if (!selectedConnection) {
        return "#D8D8D8";
      }
      return !deviceStatus ? "#FF0000" : "#1488f5";
    };

    const getTextColor = () => {
      if (!selectedConnection) {
        return "#000000";
      }
      return "#FFFFFF";
    };

    const newColor = getButtonColor();
    const newTextColor = getTextColor();
    setButtonColor(newColor);
    setTextColor(newTextColor);
  }, [deviceStatus, selectedConnection]);

  return (
    <CCard style={{
      backgroundColor: "#DAEEF5",
      ...(isTabletLandscape ? {
        maxWidth: '200px',
        height: '280px',
        marginTop: '-0.5rem'
      } : isTablet ? {
        maxWidth: '220px',
        height: '280px',
        marginTop: '1rem'
      } : {})
    }}>
      <CCardBody>
        <CCardTitle>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: isTablet ? "60px" : isTabletLandscape ? "70px" : "150px",
          }}>
            <TiPlug
              style={{
                color: "#1488f5",
                ...(isTabletLandscape ? {
                  margin: '-20px 0'
                } : {})
              }}
              size={isTablet ? "40" : isTabletLandscape ? "60" : "100"}
            />
          </div>
          <h4 style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isTabletLandscape ? {
              fontSize: '16px',
              margin: '0.5rem 0'
            } : isTablet ? {
              fontSize: '14px',
              margin: '1rem 0'
            } : {})
          }}>
            Select Your Hardware
          </h4>
        </CCardTitle>
        <div style={{
          width: "100%",
          marginLeft: isTabletLandscape ? "1px" : isTablet ? "5px" : "10px",
          marginBottom: isTablet ? "5px" : "10px",
        }}>
          <CreatableSelect
            id="device"
            className="classic"
            value={selectedConnection
              ? {
                  value: selectedConnection.id,
                  label: selectedConnection.machine_name,
                }
              : null
            }
            placeholder="Select Device"
            options={connections?.map((device) => ({
              value: device.id,
              label: device.machine_name,
            }))}
            isSearchable={true}
            formatCreateLabel={(inputValue) => inputValue}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setSelectedConnection(
                  connections.find((conn) => conn.id === selectedOption.value)
                );
              }
            }}
            styles={isTablet || isTabletLandscape ? {
              control: (base) => ({
                ...base,
                minHeight: '30px',
                height: '30px'
              }),
              valueContainer: (base) => ({
                ...base,
                height: '30px',
                fontSize: '12px',
                padding: '1px 6px'
              }),
              input: (base) => ({
                ...base,
                margin: '0px',
                fontSize: '12px',
              }),
              indicatorsContainer: (base) => ({
                ...base,
                height: '30px',
              }),
              option: (base) => ({
                ...base,
                fontSize: '12px',
                padding: '2px 6px'
              })
            } : undefined}
          />
        </div>

        <CCardText>
          <CRow style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isTablet && {
              marginTop: '5px'
            })
          }}>
            <CCol style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
              <CRow>
                <small style={{
                  color: "grey",
                  ...(isTablet ? {
                    fontSize: '10px',
                  } : isTabletLandscape ? {
                    fontSize: '10px',
                    marginTop: '0.3rem'
                  } : {})
                }}>Status</small>
              </CRow>

              <CRow>
                <small style={{
                  fontFamily: "Expansiva, sans-serif",
                  display: "flex",
                  flexDirection: "row",
                  ...(isTablet || isTabletLandscape ? {
                    fontSize: '10px'
                  } : {})
                }}>
                  <GetTestStatus test={deviceStatus} />
                  {deviceStatus ? "Active" : "Inactive"}
                </small>
              </CRow>
            </CCol>
            <CCol style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
              <CRow>
                <small style={{
                  color: "grey",
                  ...(isTablet ? {
                    fontSize: '10px'
                  } : isTabletLandscape ? {
                    fontSize: '10px',
                    marginTop: '0.3rem'
                  } : {})
                }}>Previous Date</small>
              </CRow>

              <CRow>
                <small style={{
                  fontFamily: "Expansiva, sans-serif",
                  fontSize: isTablet || isTabletLandscape ? "9px" : "0.7rem",
                }}>
                  {selectedConnection?.dated?.replace(/-/g, ".").slice(0, 10)}
                </small>
              </CRow>
            </CCol>
          </CRow>
        </CCardText>
        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          ...(isTablet || isTabletLandscape ? {
            marginTop: '5px'
          } : {})
        }}>
          <CButton
            className="centeralized no-margin"
            style={{
              backgroundColor: buttonColor,
              color: textColor,
              ...(isTablet || isTabletLandscape ? {
                padding: '5px 10px',
                fontSize: '15px'
              } : {})
            }}
            color="primary"
            onClick={handleConnections}
            title={!deviceStatus ? "Click to connect hardware" : ""}
          >
            {!deviceStatus ? (
              <BsExclamationCircle style={{
                marginRight: isTablet || isTabletLandscape ? "3px" : "5px",
                width: isTablet || isTabletLandscape ? "12px" : "20px",
                height: isTablet || isTabletLandscape ? "12px" : "20px"
              }} />
            ) : (
              <CiCircleCheck style={{
                marginRight: isTablet || isTabletLandscape ? "3px" : "5px",
                width: isTablet || isTabletLandscape ? "12px" : "20px",
                height: isTablet || isTabletLandscape ? "12px" : "20px"
              }} />
            )}
            {deviceStatus ? "Completed" : "Connect Hardware"}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default SelectYourHardware;
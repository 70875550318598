import { useEffect, useState } from 'react';
import jwtInterceptor from '../views/shared/jwtInterceptor';

// Define the type for a user
interface User {
	id: number;
	name?: string;
	email: string;
	user_type?: string;
	company?: number;
	is_admin?: boolean;
	first_name?: string;
	last_name?: string;
	applications?: number[];
}

// Custom hook to fetch current company users with access to Extracto
const useCurrentCompanyUsersWithAccessToExtracto = () => {
	const isDemoEnvironment = window.location.href.includes('demo');
	const [currentCompanyUsersWithAccessToExtracto, setCurrentCompanyUsersWithAccessToExtracto] = useState<User[] | null>(null);
	
	useEffect(() => {
		const fetchCurrentCompanyUsersWithAccessToExtracto = async () => {
			if (isDemoEnvironment) {
				// Mock data to simulate the User[] structure
				const demoData: User[] = [
					{ id: 1, first_name: "Operator", last_name: "1", email: "operator1@example.com" },
					{ id: 2, first_name: "Operator", last_name: "2", email: "operator2@example.com" },
					{ id: 3, first_name: "Operator", last_name: "3", email: "operator3@example.com" },
					{ id: 4, first_name: "Operator", last_name: "4", email: "operator4@example.com" },
				];
				
				setCurrentCompanyUsersWithAccessToExtracto(demoData);
			} else {
				try {
					const response = await jwtInterceptor.get<User[]>(`${process.env.REACT_APP_API_URL}/user/api/company-users/users_with_industrial_app/`);
					setCurrentCompanyUsersWithAccessToExtracto(response.data);
				} catch (err) {
					console.error(err);
				}
			}
		};
		
		fetchCurrentCompanyUsersWithAccessToExtracto();
	}, [isDemoEnvironment]); // Add dependency to ensure effect runs when environment changes
	
	return currentCompanyUsersWithAccessToExtracto;
};

export default useCurrentCompanyUsersWithAccessToExtracto;
